import ApiRequest from 'store/shared/api/apiRequest';
import { CarrierConnection, QuerycarrierConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { facetGroups } from 'store/shared/api/graph/schema/connection';

export type GetCarrierListArgs = Omit<QuerycarrierConnectionArgs, 'deliveryRegions' | 'shipmentModes'> & {
  deliveryRegion?: string | null;
  shipmentMode?: string | null;
};
export const getCarriersList = ({
  first = null,
  last = null,
  after = null,
  before = null,
  status = null,
  shipmentMode = null,
  deliveryRegion = null,
  deliveryRegionCodes = null,
  keyword = null,
}: GetCarrierListArgs) =>
  ApiRequest<{ carrierConnection: CarrierConnection }>({
    data: {
      query: `
      query(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $status: String
        $shipmentMode: [String!]
        $deliveryRegion: [String!]
        $deliveryRegionCodes: [String!]
        $keyword: String

      ) {
        carrierConnection(
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          status: $status,
          shipmentModes: $shipmentMode
          deliveryRegions: $deliveryRegion
          deliveryRegionCodes: $deliveryRegionCodes
          keyword: $keyword
        ) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
            totalEdges
          }
          edges {
            node {
              id
              name
              deliveryIsLocalOnly
              deliveryNotes
              deliveryWhitelistedRegions {
                region
                regionCode
              }
              contacts {
                name,
                created
              }
              primaryLocation {
                address1
                address2
                city
                regionCode
              }
              shipmentModes
              created
              updated
            }
          }
          ${facetGroups}
        }
      }
    `,
      variables: {
        first,
        last,
        after,
        before,
        status,
        shipmentMode,
        deliveryRegion,
        deliveryRegionCodes,
        keyword,
      },
    },
  });
