import ApiRequest from 'store/shared/api/apiRequest';
import {
  EmailContent,
  Mutation,
  MutationtransportRequestUpdateArgs,
  QuerytransportFeeQuoteArgs,
  TransportFeeQuote,
  TransportJob,
  TransportRequest,
} from 'store/shared/api/graph/interfaces/types';
import { locationFragment } from 'store/shared/api/graph/fragments/common';

// TODO: Use `photoFragment`
const getTransportRequestSchema = () => `
  __typename
  id
  status
  paidBy
  carrierNote
  isCloneable
  isCancellable
  notes {
    id
    content
    createdBy {
      firstName
      lastName
    }
    created
  }
  auctionItem {
    id
    created
    format
    status
    transactionDetails {
      buyerCompany {
        id
        name
      }
      invoicePaid
    }
    arbitrationRequest {
      status
    }
    releaseStatus
  }
  inventoryItem {
    ...on Vehicle {
      id
      created
      asIsCause
      autoGradeScore
      bodyType
      captureType
      chargingCable
      cylinders
      description
      displacement
      driveTrain
      exteriorColor
      fuelType
      interiorColor
      make
      model
      numberOfDoors
      numberOfPassengers
      stockNumber
      subModel
      transmission
      trim
      verifiedBy
      vin
      year
      company {
        id
        name
      }
      conditionReport {
        declarations(selected:true) {
          id
          name
        }
      }
      readyForPickup
      photos (first: 1) {
        ...thumbnailListFrag
      }
      company {
        name
      }
      vin
    }
  }
  pickupLocation {
    ...location
  }
  destinationLocation {
    ...location
  }
  createdBy {
    firstName
    lastName
  }
  cancelledBy {
    firstName
    lastName
  }
  cancellationDate
  cancellationReason
  cancellationReasonComment
  cancellationInitiator
  created
  updated
  type
`;

// TODO: Use `photoFragment`
const transportJob = `
  __typename
  id
  transportJobStatus: status # GraphQL requires this to be an alias
  jobNumber
  activeRequestCount
  notes {
    id
    content
    createdBy {
      firstName
      lastName
    }
    created
  }
  quotes {
    id
    estimatedDeliveryDateMin
    requestQuotes {
      transportRequestId
      estimatedAmount {
        amount
        formattedAmount
        formattedAmountRounded
      }
      finalAmount {
        amount
        formattedAmount
        formattedAmountRounded
      }
      chargedAmount {
        amount
        formattedAmount
        formattedAmountRounded
      }
      estimatedPickupDateMin
      estimatedPickupDateMax
      estimatedDeliveryDateMin
      estimatedDeliveryDateMax
      finalDeliveryDate
      selected
      inventoryItem {
        ...on Vehicle {
          vin
        }
      }
      additionalNotes
      adjustedDeliveryDate
      adjustedPickupDate
    }
    carrier {
      id
      name
      sdCarrierGuid
    }
    shipmentMode
    estimatedAmount {
      amount
      formattedAmount
    }
    finalAmount {
      amount
      formattedAmount
    }
    notes
    createdBy {
      firstName
      lastName
    }
    created
    updated
  }
  acceptedQuote {
    id
    requestQuotes {
      transportRequestId
      estimatedAmount {
        amount
        formattedAmount
        formattedAmountRounded
      }
      finalAmount {
        amount
        formattedAmount
        formattedAmountRounded
      }
      chargedAmount {
        amount
        formattedAmount
        formattedAmountRounded
      }
      estimatedPickupDateMin
      estimatedPickupDateMax
      estimatedDeliveryDateMin
      estimatedDeliveryDateMax
      finalDeliveryDate
      selected
      inventoryItem {
        ...on Vehicle {
          vin
        }
      }
      additionalNotes
      adjustedDeliveryDate
      adjustedPickupDate
    }
    carrier {
      id
      name
    }
    shipmentMode
    notes
    created
    createdBy {
      firstName
      lastName
    }
    updated
    estimatedPickupDateMin
    estimatedPickupDateMax
    estimatedDeliveryDateMin
    estimatedDeliveryDateMax
    finalDeliveryDateMin
    finalDeliveryDateMax
    estimatedAmount {
      amount
      formattedAmount
    }
    finalAmount {
      formattedAmount
    }
    chargedAmount {
      formattedAmount
    }
  }
  transportRequests {
    ${getTransportRequestSchema()}
  }
  isCancellable
  createdBy {
    id
    firstName
    lastName
  }
  syncedWithSuperDispatch
  created
  updated
`;

export const getTransportJob = ({ transportJobId = null }) =>
  ApiRequest<{ transportJob: TransportJob; transportRequest: TransportRequest }>({
    data: {
      operationName: 'getTransportJobDetails',
      query: `
      query ($transportJobId: String!) {
        transportJob(transportJobId: $transportJobId) {
          ${transportJob}
        }
        transportRequest(transportRequestId: $transportJobId) {
          ${getTransportRequestSchema()}
        }
      }
      ${locationFragment}
      fragment thumbnailListFrag on Photo {
        main: url (width: 800, height: 600)
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        transportJobId,
      },
    },
  });

export const saveTransportJob = ({ id = null, transportRequestIds = null, isAdding = null }) => {
  const mutation = !isAdding ? 'transportJobUpdate' : 'transportJobCreate';
  return ApiRequest<{
    [key in typeof mutation]: Mutation[key];
  }>({
    data: {
      query: `
      mutation (
        ${!isAdding ? '$id: String!' : ''}
        $transportRequestIds: [String!]!
      ) {
        ${mutation}(
          ${!isAdding ? 'id: $id' : ''}
          transportRequestIds: $transportRequestIds
        ) {
          ${transportJob}
        }
      }
      ${locationFragment}
      fragment thumbnailListFrag on Photo {
        main: url (width: 800, height: 600)
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        id,
        transportRequestIds,
      },
    },
  });
};

export const saveQuote = ({
  id = null,
  transportJobId = null,
  carrierId = null,
  shipmentMode = null,
  notes = null,
  transportRequestQuotes = null,
}) => {
  const mutation = id ? 'transportJobQuoteUpdate' : 'transportJobQuoteCreate';
  return ApiRequest<{
    [key in typeof mutation]?: Mutation[key];
  }>({
    data: {
      query: `
      mutation (
        $id: String,
        $transportJobId: String!,
        $carrierId: String,
        $shipmentMode: CarrierShipmentMode,
        $notes: String,
        $transportRequestQuotes: [TransportRequestQuoteInput!]!
      ) {
        ${mutation}(
          input: {
            id: $id,
            transportJobId: $transportJobId,
            carrierId: $carrierId,
            shipmentMode: $shipmentMode,
            notes: $notes,
            transportRequestQuotes: $transportRequestQuotes
          }
        ) {
            ${transportJob}
          }
        }
        ${locationFragment}
        fragment thumbnailListFrag on Photo {
          main: url (width: 800, height: 600)
          thumb: url (width: 110, height: 110)
        }
    `,
      variables: {
        id,
        transportJobId,
        carrierId,
        shipmentMode,
        notes,
        transportRequestQuotes,
      },
    },
  });
};

export const acceptQuote = ({ syncWithSuperDispatch, transportJobId, transportJobQuoteId }) =>
  ApiRequest<{ transportJobQuoteAccept: TransportJob }>({
    data: {
      query: `
      mutation ($syncWithSuperDispatch: Boolean!, $transportJobId: String!, $transportJobQuoteId: String!) {
        transportJobQuoteAccept(syncWithSuperDispatch: $syncWithSuperDispatch, transportJobId: $transportJobId, transportJobQuoteId: $transportJobQuoteId) {
          ${transportJob}
        }
      }
      ${locationFragment}
      fragment thumbnailListFrag on Photo {
        main: url (width: 800, height: 600)
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        syncWithSuperDispatch,
        transportJobId,
        transportJobQuoteId,
      },
    },
  });

export const inviteCarriers = ({ id = null, carrierIds = null }) =>
  ApiRequest<{ transportJobQuoteInvite: TransportJob }>({
    data: {
      query: `
      mutation(
       $id: String!
       $carrierIds: [String!]!
      ) {
        transportJobQuoteInvite(id:$id, carrierIds: $carrierIds) {
          ${transportJob}
        }
      }
      ${locationFragment}
      fragment thumbnailListFrag on Photo {
        main: url (width: 800, height: 600)
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        id,
        carrierIds,
      },
    },
  });

export const cancelTransportJob = ({
  id = null,
  initiator = null,
  cancellationReason = null,
  cancellationReasonComment = null,
  cloneRequestIds = null,
}) =>
  ApiRequest<{ transportJobCancelAll: TransportJob }>({
    data: {
      query: `
        mutation(       
          $id: String!,
          $initiator: TransportCancellationInitiator!,
          $cancellationReason: TransportCancellationReason!,
          $cancellationReasonComment: String,
          $cloneRequestIds: [String!]
        ) {
          transportJobCancelAll(
            id: $id,
            initiator: $initiator,
            cancellationReason: $cancellationReason,
            cancellationReasonComment: $cancellationReasonComment,
            cloneRequestIds: $cloneRequestIds
          ) {
            ${transportJob}
          }
        }
        ${locationFragment}
        fragment thumbnailListFrag on Photo {
          main: url (width: 800, height: 600)
          thumb: url (width: 110, height: 110)
        }
      `,
      variables: {
        id,
        initiator,
        cancellationReason,
        cancellationReasonComment,
        cloneRequestIds,
      },
    },
  });

export const transportRequestUpdate = ({
  carrierNote = null,
  destinationLocationId = null,
  id,
  paidBy = null,
  pickupLocationId = null,
}: MutationtransportRequestUpdateArgs) =>
  ApiRequest<{ transportRequestUpdate: TransportRequest }>({
    data: {
      query: `
    mutation(
      $id: String!
      $pickupLocationId: String
      $destinationLocationId: String
      $carrierNote: String
      $paidBy: TransportPaidBy
    ) {
      transportRequestUpdate(
        id: $id
        pickupLocationId: $pickupLocationId
        destinationLocationId: $destinationLocationId
        carrierNote: $carrierNote
        paidBy: $paidBy
      ) {
        ${getTransportRequestSchema()}
      }
    }
    ${locationFragment}
    fragment thumbnailListFrag on Photo {
      main: url (width: 800, height: 600)
      thumb: url (width: 110, height: 110)
    }
    `,
      variables: {
        id,
        pickupLocationId,
        destinationLocationId,
        carrierNote,
        paidBy,
      },
    },
  });

export const cancelTransportRequest = ({
  id = null,
  initiator = null,
  cancellationReasonComment = null,
  cancellationReason = null,
  clone = null,
}) =>
  ApiRequest<{ transportRequestCancel: TransportRequest }>({
    data: {
      query: `
      mutation ($id: String!, $initiator: TransportCancellationInitiator!, $cancellationReason: TransportCancellationReason!, $cancellationReasonComment: String, $clone: Boolean) {
        transportRequestCancel(id: $id, initiator: $initiator, cancellationReason: $cancellationReason, cancellationReasonComment: $cancellationReasonComment, clone: $clone) {
          ${getTransportRequestSchema()}
        }
      }
      ${locationFragment}
      fragment thumbnailListFrag on Photo {
        main: url (width: 800, height: 600)
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        id,
        cancellationReason,
        cancellationReasonComment,
        initiator,
        clone,
      },
    },
  });

export const transportRequestPickedUp = ({ id = null, pickupDate = null }) =>
  ApiRequest<{ transportRequestPickedUp: TransportRequest }>({
    data: {
      query: `
        mutation ($id: String!, $pickupDate: Date) {
          transportRequestPickedUp(id: $id, pickupDate: $pickupDate) {
            ${getTransportRequestSchema()}
          }
        }
        ${locationFragment}
        fragment thumbnailListFrag on Photo {
          main: url (width: 800, height: 600)
          thumb: url (width: 110, height: 110)
        }
    `,
      variables: {
        id,
        pickupDate,
      },
    },
  });

export const transportRequestDelivered = ({
  id = null,
  chargedAmount = null,
  deliveryDate = null,
  finalAmount = null,
}) =>
  ApiRequest<{ transportRequestDelivered: TransportRequest }>({
    data: {
      query: `
      mutation ($id: String!, $deliveryDate: Date, $finalAmount: Float, $chargedAmount: Float) {
        transportRequestDelivered(id: $id, deliveryDate: $deliveryDate, finalAmount: $finalAmount, chargedAmount: $chargedAmount) {
          ${getTransportRequestSchema()}
        }
      }
      ${locationFragment}
      fragment thumbnailListFrag on Photo {
        main: url (width: 800, height: 600)
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        id,
        chargedAmount,
        deliveryDate,
        finalAmount,
      },
    },
  });

export const getEmailContentReleaseForTransport = ({ auctionItemId = null }) =>
  ApiRequest<{ emailContentReleaseForTransport: EmailContent }>({
    data: {
      query: `
      mutation ($auctionItemId: String!) {
        emailContentReleaseForTransport(auctionItemId: $auctionItemId) {
          emailTo
          subject
          message
          attachmentURLs
        }
      }
    `,
      variables: { auctionItemId },
    },
  });

export const getEmailContentReleaseForBuyerPickup = ({ auctionItemId = null }) =>
  ApiRequest<{ emailContentReleaseForBuyerPickup: EmailContent }>({
    data: {
      query: `
      mutation ($auctionItemId: String!) {
        emailContentReleaseForBuyerPickup(auctionItemId: $auctionItemId, releaseForTransport: false) {
          emailTo
          subject
          message
          attachmentURLs
        }
      }
    `,
      variables: { auctionItemId },
    },
  });

export const sendAuctionItemRelease = ({ input, isTransportForm = false }) => {
  const mutation = isTransportForm ? 'auctionItemReleaseForTransport' : 'auctionItemReleaseForBuyerPickup';
  return ApiRequest<{ [key in typeof mutation]: Mutation[key] }>({
    data: {
      query: `
      mutation($input: ${isTransportForm ? 'ReleaseForTransportInput' : 'ReleaseForBuyerPickupInput'}) {
        ${mutation}(input: $input) {
          id
        }
      }
    `,
      variables: { input },
    },
  });
};

export const transportFeeQuote = ({ auctionItemId, dropOffLocationId }: QuerytransportFeeQuoteArgs) =>
  ApiRequest<{ transportFeeQuote: TransportFeeQuote }>({
    data: {
      operationName: 'transportFeeQuote',
      query: `
      query ($auctionItemId: String!, $dropOffLocationId: String!) {
        transportFeeQuote (
          auctionItemId: $auctionItemId,
          dropOffLocationId: $dropOffLocationId,
        ) {
          amount {
            formattedAmount
          }
          disclaimers
          dropOffLocation {
            address1
            address2
            city
            country
            countryCode
            id
            latitude
            longitude
            name
            region
            regionCode
            zipCode
          }
        }
      }
    `,
      variables: {
        auctionItemId,
        dropOffLocationId,
      },
    },
  });
