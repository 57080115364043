// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_e940b85","type-body-2":"type-body-2_f7b809b","type-body-3":"type-body-3_bdb98dc","type-body-4":"type-body-4_dde1bf9","type-section-title":"type-section-title_b5d8880","type-notes":"type-notes_bd0d3c2","type-item-title":"type-item-title_a1b2c33","type-item-subtitle":"type-item-subtitle_d37a6a9","type-subtitle":"type-subtitle_c6c3501","type-title":"type-title_b9a3831","facetGroups":"facetGroups_ec045e8","titleContainer":"titleContainer_f811c3c","title":"title_aa0a594","heading":"heading_b61affa","spacer":"spacer_fa001f0","showMyUnitsSwitch":"showMyUnitsSwitch_e40707d","radio":"radio_d1a869e","selected":"selected_c43d1b2","bulge":"bulge_b50745d"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_e940b85\",\"type-body-2\":\"type-body-2_f7b809b\",\"type-body-3\":\"type-body-3_bdb98dc\",\"type-body-4\":\"type-body-4_dde1bf9\",\"type-section-title\":\"type-section-title_b5d8880\",\"type-notes\":\"type-notes_bd0d3c2\",\"type-item-title\":\"type-item-title_a1b2c33\",\"type-item-subtitle\":\"type-item-subtitle_d37a6a9\",\"type-subtitle\":\"type-subtitle_c6c3501\",\"type-title\":\"type-title_b9a3831\",\"facetGroups\":\"facetGroups_ec045e8\",\"titleContainer\":\"titleContainer_f811c3c\",\"title\":\"title_aa0a594\",\"heading\":\"heading_b61affa\",\"spacer\":\"spacer_fa001f0\",\"showMyUnitsSwitch\":\"showMyUnitsSwitch_e40707d\",\"radio\":\"radio_d1a869e\",\"selected\":\"selected_c43d1b2\",\"bulge\":\"bulge_b50745d\"}";
        // 1726239932681
        var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  