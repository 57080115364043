import { ReactNode, useCallback, useMemo, useState } from 'react';

import AddModifyInventoryItemContainer from 'containers/inventoryItem/addModify/addModifyContainer';
import AnchorTabs, { SCROLLABLE_CONTENT_ID } from 'components/ui/shared/tabs/anchorTabs';
import Ended from 'components/sections/auctionItem/details/ended';
import InventoryItemDetails from 'components/sections/inventoryItem/details/inventoryItemDetails';
import NotAvailable from 'components/sections/auctionItem/details/notAvailable';
import Operations from 'components/sections/inventoryItem/operations/operations';
import PhysicalDetailsHeader from 'components/sections/auctionItem/details/physicalDetailsHeader';
import Status from 'components/sections/auctionItem/details/status/status';
import User from 'constants/user';
import VinDetails from 'components/sections/inventoryItem/details/vinDetails';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { AuctionItemStatus, LiveLane, TopOffer } from 'store/shared/api/graph/interfaces/types';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import { FeatureFlag } from 'constants/featureFlags';
import { HeaderType } from 'components/sections/auctionItem/details/header';
import { Location } from 'constants/reactRouter';
import { LooseObject } from 'constants/objects';
import { PaneIndex } from 'components/sections/inventoryItem/addModify/vehicleForm';
import { SectionNavigation } from 'components/sections/inventoryItem/addModify/interfaces/vehicleForm';
import { Spinner } from 'components/ui/loading/loading';
import { getAnchorTabsConfig } from 'components/sections/inventoryItem/details/anchorTabs';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { joinStrings } from 'utils/stringUtils';

import style from './auctionItemDetailsPhysical.scss';

interface Props {
  /** Auction item details. */
  auctionItemDetails: AuctionItemDetailsProps;

  /** Rendered child elements. */
  children: ReactNode;

  /** Function invoked to clear auction item. */
  clearAuctionItem: () => void;

  /** Function invoked when inventory option clicked. */
  handleFeatureClicked: (featureType: string, inventoryDetailsData?: LooseObject) => void;

  /** True when the auction details are updating. */
  isUpdating: boolean;

  /** True when user does not have permission to view the auction item.*/
  isPermissionDenied: boolean;

  /** The live lane for the auction item. */
  liveLane: LiveLane | undefined;

  /** The current url information for the component. */
  location: Location;

  /** Function Invoked when dialog closed. */
  onEndedClose: () => void;

  /** Callback function to refresh the list. */
  refreshList: (() => void) | undefined;

  /** Whether to show auction is ended or not. */
  showEnded: boolean;

  /** The top offer */
  topOffer?: TopOffer | null;

  /** The total bids applied to the current unit */
  totalBids: number;

  /** The unique bidders for to the current unit */
  uniqueBidders: string[];

  /** Current user. */
  user: User;
}

const AuctionItemDetailsPhysical = ({
  auctionItemDetails,
  children,
  clearAuctionItem,
  handleFeatureClicked,
  isPermissionDenied,
  isUpdating,
  liveLane,
  location,
  onEndedClose,
  refreshList,
  showEnded,
  topOffer,
  totalBids,
  uniqueBidders,
  user,
}: Props) => {
  const { asIs, format, inventoryItem, isAssured, isMyItem } = auctionItemDetails;
  const { model, trim, year, make, photos } = inventoryItem;
  const subtitle = joinStrings([model, trim].filter(Boolean), ' ');
  const thumbnail = photos?.[0]?.main;
  const title = `${year} ${make}`;
  const isUpdatable = inventoryItem.isUpdatable;

  /**
   * Memoized anchor tabs configuration
   */
  const anchorTabsConfig = useMemo(
    () => getAnchorTabsConfig(inventoryItem, auctionItemDetails, user, true),
    [auctionItemDetails, inventoryItem, user]
  );
  const anchorTabs = Object.values(anchorTabsConfig);

  const [isAddModifyOpen, setAddModifyOpen] = useState<boolean>(false);
  const [navigateToSection, setNavigateToSection] = useState<SectionNavigation>({
    paneIndex: null,
    section: '',
  });

  /**
   * onOpenAddModifyModal
   */
  const openAddModifyModal = useCallback((section, paneIndex) => {
    setAddModifyOpen(true);
    setNavigateToSection({ section, paneIndex });
  }, []);

  /**
   * Handles vin details click event. Opens up the modify modal
   * pointing to vin details
   */
  const handleVinDetailsOnClick = useCallback(() => {
    return openAddModifyModal('vinDetails', PaneIndex.VEHICLE_DETAILS);
  }, [openAddModifyModal]);

  /**
   * Refreshes list when auction item is cancelled or no sale
   */
  const onRefreshList = useMemo(() => {
    return [AuctionItemStatus.SALE_CANCELLED, AuctionItemStatus.NO_SALE].includes(auctionItemDetails.status)
      ? refreshList
      : undefined;
  }, [auctionItemDetails.status, refreshList]);

  return (
    <>
      <div className={style.details}>
        <div className={style.contentWrapper} id={SCROLLABLE_CONTENT_ID}>
          <PhysicalDetailsHeader
            auctionItem={auctionItemDetails}
            handleFeatureClicked={handleFeatureClicked}
            liveItem={liveLane?.liveItem || undefined}
            photoUrl={thumbnail}
            subtitle={subtitle}
            title={title}
            user={user}
          />
          {hasFeatureFlag(FeatureFlag.SIMULCAST) && (
            <AnchorTabs defaultSelected={0} headerType={HeaderType.LIVE_LANES} isSticky tabs={anchorTabs} />
          )}
          {isUpdating ? (
            <section className={style.content}>
              <Spinner className={style.detailsLoader} dataTestId="details-spinner" />
            </section>
          ) : (
            <Switch>
              <Case if={!isPermissionDenied}>
                <Operations headerType={HeaderType.LIVE_LANES} onRefreshList={onRefreshList} />
                {inventoryItem.vin && (
                  <section>
                    <VinDetails
                      auctionItem={auctionItemDetails}
                      isUpdatable={isUpdatable}
                      onUpdateButtonClick={handleVinDetailsOnClick}
                      vin={inventoryItem.vin}
                    />
                  </section>
                )}
                <section id={anchorTabsConfig.AUCTION_DETAILS.id}>
                  <Status auctionItemDetails={auctionItemDetails} handleFeatureClicked={handleFeatureClicked} />
                </section>
                <section data-testid="inventory-item-details-section">
                  <InventoryItemDetails
                    anchorTabsConfig={anchorTabsConfig}
                    auctionItem={auctionItemDetails}
                    handleFeatureClicked={handleFeatureClicked}
                    inventoryItemDetails={inventoryItem}
                    isAsIs={asIs}
                    isAssured={isAssured}
                    isAuctionItemView
                    isMyItem={isMyItem}
                    isUpdatable={isUpdatable}
                    location={location}
                    openAddModifyModal={openAddModifyModal}
                    user={user}
                  />
                </section>
              </Case>
              <Case>
                <section className={style.content}>
                  <NotAvailable onClose={clearAuctionItem} />
                </section>
              </Case>
            </Switch>
          )}
        </div>
        {children}
      </div>
      {showEnded && (
        <Ended
          currencyCode={auctionItemDetails?.furtherBidIncrement?.currencyCode}
          format={format}
          isInAuctionItemsList
          isMyItem={isMyItem}
          onClose={() => onEndedClose()}
          topOffer={topOffer || undefined}
          totalBids={totalBids}
          uniqueBidders={uniqueBidders}
          user={user}
        />
      )}
      <AddModifyInventoryItemContainer
        auctionItemId={auctionItemDetails.id}
        closeModal={() => setAddModifyOpen(false)}
        inventoryItemId={auctionItemDetails.inventoryItem.id}
        isOpen={isAddModifyOpen}
        navigateToSection={navigateToSection}
      />
    </>
  );
};

export default AuctionItemDetailsPhysical;
