import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import holdbackGlyph from 'glyphs/holdback.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import IconButton from 'components/ui/shared/buttons/iconButton';
import { AmountType, AuctionItemFormat } from 'store/shared/api/graph/interfaces/types';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors } from 'utils/apiUtils';
import { hasCompanyPermission } from 'utils/userUtils';
import { processSetHoldback } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './setHoldback.scss';

const stateConnect = (state: AppState) => ({
  /** Current logged in user. */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Dispatch function to update the hold back. */
  setHoldback: (
    auctionItemId: string,
    holdbackActive: boolean,
    type: AmountType | undefined,
    amount: number | undefined
  ) => processSetHoldback({ auctionItemId, holdbackActive, type, amount }, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The auction item details.*/
  auctionItem: AuctionItem;
  /** CSS styling to overwrite the default button style. */
  className?: string;
}

interface State {
  /** Validation Errors. */
  errorMessages: ErrorMessages | null;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** True when form is being submitted. */
  isSubmitting: boolean;
}

class SetHoldback extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isOpen: false, errorMessages: null, isSubmitting: false };
  }

  /**
   * Function called on holdback dialog confirm clicked.
   */
  onSubmit = async (shouldSubmit: boolean, isActive?: boolean, type?: AmountType, amount?: number) => {
    const { auctionItem, setHoldback } = this.props;

    if (shouldSubmit) {
      this.setState({ isSubmitting: true });
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_HOLDBACK_CONFIRM_CLICK, auctionItem);
      try {
        await setHoldback(auctionItem?.id, !!isActive, type, amount);
        this.setState({ isSubmitting: false, isOpen: false });
      } catch (error) {
        const errorMessages = getErrors(error).map((err) => err.message);
        this.setState({ errorMessages, isSubmitting: false });
      }
    } else {
      this.setState({ isSubmitting: false, isOpen: false });
    }
  };

  /**
   * Function called on holdback button clicked.
   */
  onClick = () => {
    const { auctionItem } = this.props;
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_HOLDBACK_CLICK, auctionItem);
    this.setState({ isOpen: true, errorMessages: null });
  };

  render() {
    const { auctionItem, className, user } = this.props;
    const { errorMessages, isOpen, isSubmitting } = this.state;
    const isActive = auctionItem?.holdbackActive;
    const isVisible =
      auctionItem?.format === AuctionItemFormat.APPRAISAL &&
      (auctionItem?.inventoryItem?.company?.rolePermissionsEnabled
        ? // Check for a valid company permission if `rolePermissionsEnabled === true`
          hasCompanyPermission(user, auctionItem?.inventoryItem?.company?.id, ['COMPANY_MANAGE_HOLDBACK'])
        : // Otherwise, ensure the unit belongs to the user
          auctionItem?.isMyItem);

    if (!isVisible) {
      return null;
    }

    return [
      <IconButton
        key="button"
        className={classnames(className, { [style.isActive]: isActive })}
        dataTestId="set-holdback-button"
        glyph={holdbackGlyph}
        onClick={this.onClick}
        spriteClassName={style.sprite}
      />,
      <Dialog
        key="dialog"
        companyId={auctionItem?.inventoryItem?.company?.id}
        defaultHoldback={auctionItem?.inventoryItem?.company?.holdback}
        errorMessages={errorMessages}
        holdback={auctionItem?.holdback}
        isActive={isActive}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
        onSubmit={this.onSubmit}
      />,
    ];
  }
}

export default connector(SetHoldback);
