/**
 * User actions
 */
export enum UserAction {
  /**
   * Legacy UserActions
   *
   * TODO: These should be converted to the new format, and adopted by mobile clients
   */
  INCLUDE_IF_BIDS = 'include_if_bids',
  RUN_AGAIN = 'run_again',
  SEARCH = 'search',
  SUBMIT_TO_AUCTION = 'submit_to_auction',
  VIEW_VDP = 'view_vdp',

  ALREADY_MEMBER_CLICK = 'already_member_click',
  AUTOCHECK_CLICK = 'autocheck_click',
  AUTOGRADE_SCORE_CLICK = 'autograde_score_click',
  BUY_NOW_LIST_BUY_NOW_CANCEL_CLICK = 'buy_now_list_buy_now_cancel_click',
  BUY_NOW_LIST_BUY_NOW_CLICK = 'buy_now_list_buy_now_click',
  BUY_NOW_LIST_BUY_NOW_CONFIRM_CLICK = 'buy_now_list_buy_now_confirm_click',
  BUY_NOW_LIST_MAKE_OFFER_CANCEL_CLICK = 'buy_now_list_make_offer_cancel_click',
  BUY_NOW_LIST_MAKE_OFFER_CLICK = 'buy_now_list_make_offer_click',
  BUY_NOW_LIST_MAKE_OFFER_CONFIRM_CLICK = 'buy_now_list_make_offer_confirm_click',
  CARFAX_CLICK = 'carfax_click',
  COMPARABLES_VIEW_VEHICLES_CLICK = 'comparables_view_vehicles_click',
  COMPARABLES_VIEW_VEHICLES_DETAILS_CLICK = 'comparables_view_vehicle_details_click',
  COUNTRY_CANADA_CLICK = 'country_canada_click',
  COUNTRY_OTHER_CLICK = 'country_other_click',
  COUNTRY_USA_CLICK = 'country_usa_click',
  CREATE_ACCOUNT_CLICK = 'create_account_click',
  EMPLOYEE_LOGIN_CLICK = 'employee_login_click',
  HOME_DASHBOARD_RECOMMENDED_ITEMS = 'recommended_items',
  INTERNAL_NOTES_CLICK = 'internal_notes_click',
  LIVE_AUCTION_BLOCK_BID_CANCEL_CLICK = 'live_auction_block_bid_cancel_click',
  LIVE_AUCTION_BLOCK_BID_CLICK = 'live_auction_block_bid_click',
  LIVE_AUCTION_BLOCK_BID_CONFIRM_CLICK = 'live_auction_block_bid_confirm_click',
  LIVE_AUCTION_LANE_PREFERENCE = 'live_auction_lane_preference',
  LIVE_AUCTION_LANE_PREFERENCE_CLICK = 'live_auction_lane_preference_click',
  LOAD_ACCOUNT_INFO_CLICK = 'load_account_info_click',
  MY_MARKET_GUIDE_CLICK = 'my_market_guide_click',
  NOTIFICATIONS_ADD_NEW_CANCEL_CLICK = 'notifications_add_new_cancel_click',
  NOTIFICATIONS_ADD_NEW_CLICK = 'notifications_add_new_click',
  NOTIFICATIONS_ADD_NEW_FROM_YEAR_SET = 'notifications_add_new_from_year_set',
  NOTIFICATIONS_ADD_NEW_MAKE_SET = 'notifications_add_new_make_set',
  NOTIFICATIONS_ADD_NEW_MILEAGE_SET = 'notifications_add_new_mileage_set',
  NOTIFICATIONS_ADD_NEW_MODEL_SET = 'notifications_add_new_model_set',
  NOTIFICATIONS_ADD_NEW_SAVE_CLICK = 'notifications_add_new_save_click',
  NOTIFICATIONS_ADD_NEW_TO_YEAR_SET = 'notifications_add_new_to_year_set',
  NOTIFICATIONS_CLOSE = 'notifications_close',
  NOTIFICATIONS_FILTER_DELETE_CANCEL_CLICK = 'notifications_filter_delete_cancel_click',
  NOTIFICATIONS_FILTER_DELETE_CLICK = 'notifications_filter_delete_click',
  NOTIFICATIONS_FILTER_DELETE_CONFIRM_CLICK = 'notifications_filter_delete_confirm_click',
  NOTIFICATIONS_OPEN = 'notifications_open',
  OBDII_CLICK = 'obdii_click',
  PARTNER_LOGIN_CLICK = 'partner_login_click',
  PRINT_CLICK = 'print_click',
  RECOMMENDED_FILTER_CLICK = 'recommend_filter_click',
  RECOMMEND_FOR_YOU_CLICK = 'recommend_for_you_click',
  REQUEST_INSPECTION_CLICK = 'request_inspection_click',
  REQUEST_INSPECTION_REQUEST_CLICK = 'request_inspection_request_click',
  REQUEST_INVITE_CLICK = 'request_invite_click',
  REQUEST_RESET_CLICK = 'request_reset_click',
  REVIEW_INFORMATION_CLICK = 'review_information_click',
  RUN_LIST_ADD_TO_WATCHLIST_CLICK = 'run_list_add_to_watchlist_click',
  RUN_LIST_AUTOBID_CLICK = 'run_list_autobid_click',
  RUN_LIST_AUTOBID_CONFIRM_CLICK = 'run_list_autobid_confirm_click',
  RUN_LIST_AUTOBID_REMOVE_CLICK = 'run_list_autobid_remove_click',
  SIGN_IN_CLICK = 'sign_in_click',
  SIGN_UP_CLICK = 'sign_up_click',
  SMOG_READINESS_CLICK = 'smog_readiness_click',
  TIMED_AUCTION_LIST_BID_CANCEL_CLICK = 'timed_auction_list_bid_cancel_click',
  TIMED_AUCTION_LIST_BID_CLICK = 'timed_auction_list_bid_click',
  TIMED_AUCTION_LIST_BID_CONFIRM_CLICK = 'timed_auction_list_bid_confirm_click',
  TIMED_AUCTION_LIST_BUY_NOW_CANCEL_CLICK = 'timed_auction_list_buy_now_cancel_click',
  TIMED_AUCTION_LIST_BUY_NOW_CLICK = 'timed_auction_list_buy_now_click',
  TIMED_AUCTION_LIST_BUY_NOW_CONFIRM_CLICK = 'timed_auction_list_buy_now_confirm_click',
  TIRES_CLICK = 'tires_click',
  VDP_ADD_DECLARATIONS_CLICK = 'vdp_add_declarations_click',
  VDP_ADD_OPTIONS_CLICK = 'vdp_add_options_click',
  VDP_ADD_SELLER_NOTES_CLICK = 'vdp_add_seller_notes_click',
  VDP_ADD_TO_WATCHLIST_CLICK = 'vdp_add_to_watchlist_click',
  VDP_ARCHIVE_CLICK = 'vdp_archive_click',
  VDP_AUCTION_LOCATION_EDIT_CLICK = 'vdp_auction_location_edit_click',
  VDP_AUCTION_LOCATION_EDIT_SAVE_CLICK = 'vdp_auction_location_edit_save_click',
  VDP_AUDIT_LOG_CLICK = 'vdp_audit_log_click',
  VDP_AUTOBID_CLICK = 'vdp_autobid_click',
  VDP_AUTOBID_CONFIRM_CLICK = 'vdp_autobid_confirm_click',
  VDP_AUTOBID_REMOVE_CLICK = 'vdp_autobid_remove_click',
  VDP_BID_CANCEL_CLICK = 'vdp_bid_cancel_click',
  VDP_BID_CLICK = 'vdp_bid_click',
  VDP_BID_CONFIRM_CLICK = 'vdp_bid_confirm_click',
  VDP_BILL_OF_SALE_CLICK = 'vdp_bill_of_sale_click',
  VDP_BODY_TYPE_EDIT_CLICK = 'vdp_body_type_edit_click',
  VDP_BUY_NOW_CANCEL_CLICK = 'vdp_buy_now_cancel_click',
  VDP_BUY_NOW_CLICK = 'vdp_buy_now_click',
  VDP_BUY_NOW_CONFIRM_CLICK = 'vdp_buy_now_confirm_click',
  VDP_CAPTURE_COMPLETE_CANCEL = 'vdp_capture_complete_cancel',
  VDP_CAPTURE_COMPLETE_CONFIRM = 'vdp_capture_complete_confirm',
  VDP_CHANGE_BUYER_CLICK = 'vdp_change_buyer_click',
  VDP_CHANGE_BUYER_CONFIRM_CLICK = 'vdp_change_buyer_confirm_click',
  VDP_CHANGE_SELLER_CLICK = 'vdp_change_seller_click',
  VDP_CHANGE_SELLER_CONFIRM_CLICK = 'vdp_change_seller_confirm_click',
  VDP_CUSTOM_BID_CLICK = 'vdp_custom_bid_click',
  VDP_CUSTOM_BID_CONFIRM_CLICK = 'vdp_custom_bid_confirm_click',
  VDP_DAMAGE_PHOTOS_CLICK = 'vdp_damage_photos_click',
  VDP_DOORS_EDIT_CLICK = 'vdp_doors_edit_click',
  VDP_EDIT_DECLARATIONS_CLICK = 'vdp_edit_declarations_click',
  VDP_EDIT_OPTIONS_CLICK = 'vdp_edit_options_click',
  VDP_EDIT_SELLER_NOTES_CLICK = 'vdp_edit_seller_notes_click',
  VDP_EXTERIOR_COLOUR_EDIT_CLICK = 'vdp_exterior_colour_edit_click',
  VDP_HOLDBACK_CLICK = 'vdp_holdback_click',
  VDP_HOLDBACK_CONFIRM_CLICK = 'vdp_holdback_confirm_click',
  VDP_IF_BID_ACCEPT_CLICK = 'vdp_if_bid_accept_click',
  VDP_IF_BID_ACCEPT_CONFIRM_CLICK = 'vdp_if_bid_accept_confirm_click',
  VDP_IF_BID_COUNTER_CLICK = 'vdp_if_bid_counter_click',
  VDP_IF_BID_COUNTER_CONFIRM_CLICK = 'vdp_if_bid_counter_confirm_click',
  VDP_INTERIOR_COLOUR_EDIT_CLICK = 'vdp_interior_colour_edit_click',
  VDP_LEGAL_SALE_DATE_EDIT_CONFIRM_CLICK = 'vdp_legal_sale_date_edit_confirm_click',
  VDP_MAKE_EDIT_CLICK = 'vdp_make_edit_click',
  VDP_MAKE_OFFER_CANCEL_CLICK = 'vdp_make_offer_cancel_click',
  VDP_MAKE_OFFER_CLICK = 'vdp_make_offer_click',
  VDP_MAKE_OFFER_CONFIRM_CLICK = 'vdp_make_offer_confirm_click',
  VDP_MILEAGE_EDIT_CLICK = 'vdp_mileage_edit_click',
  VDP_MODEL_EDIT_CLICK = 'vdp_model_edit_click',
  VDP_MODIFY_PRICING_CLICK = 'vdp_modify_pricing_click',
  VDP_MODIFY_PRICING_CONFIRM_CLICK = 'vdp_modify_pricing_confirm_click',
  VDP_PAINT_CLICK = 'vdp_paint_click',
  VDP_PASSENGERS_EDIT_CLICK = 'vdp_passengers_edit_click',
  VDP_PICK_UP_LOCATION_EDIT_CLICK = 'vdp_pick_up_location_edit_click',
  VDP_PICK_UP_LOCATION_EDIT_CONFIRM_CLICK = 'vdp_pick_up_location_edit_confirm_click',
  VDP_PICK_UP_LOCATION_VIEW_MAP_CLICK = 'vdp_pick_up_location_view_map_click',
  VDP_POTENTIAL_BUYERS_CLICK = 'vdp_potential_buyers_click',
  VDP_PRINT_CLICK = 'vdp_print_click',
  VDP_PULL_FROM_AUCTION_CLICK = 'vdp_pull_from_auction_click',
  VDP_PULL_FROM_AUCTION_CONFIRM_CLICK = 'vdp_pull_from_auction_confirm_click',
  VDP_RECENTLY_VIEWED_BY_CLICK = 'vdp_recently_viewed_by_click',
  VDP_SELL_DIRECT_CLICK = 'vdp_sell_direct_click',
  VDP_SELL_DIRECT_CONFIRM_CLICK = 'vdp_sell_direct_confirm_click',
  VDP_SET_AS_IS_CLICK = 'vdp_set_as_is_click',
  VDP_SET_AS_IS_CONFIRM_CLICK = 'vdp_set_as_is_confirm_click',
  VDP_SHARE_CLICK = 'vdp_share_click',
  VDP_SHARE_COPY_CLICK = 'vdp_share_copy_click',
  VDP_SOLD_BY_SELLER_CLICK = 'vdp_sold_by_seller_click',
  VDP_STOCK_NUM_EDIT_CLICK = 'vdp_stock_num_edit_click',
  VDP_TRANSFER_CONSIGNER_CLICK = 'vdp_transfer_consigner_click',
  VDP_TRANSFER_CONSIGNER_CONFIRM_CLICK = 'vdp_transfer_consigner_confirm_click',
  VDP_TRANSFER_SALE_CLICK = 'vdp_transfer_sale_click',
  VDP_TRANSFER_SALE_CONFIRM_CLICK = 'vdp_transfer_sale_confirm_click',
  VDP_TRANSMISSION_EDIT_CLICK = 'vdp_transmission_edit_click',
  VDP_TRANSPORT_ESTIMATE_CANCEL_CLICK = 'vdp_transport_estimates_cancel_click',
  VDP_TRANSPORT_ESTIMATE_CLICK = 'vdp_transport_estimates_click',
  VDP_TRANSPORT_ESTIMATE_CONFIRM_CLICK = 'vdp_transport_estimates_confirm_click',
  VDP_TRANSPORT_ESTIMATE_EDIT_CLICK = 'vdp_transport_estimates_edit_click',
  VDP_TRIM_EDIT_CLICK = 'vdp_trim_edit_click',
  VDP_VALUES_ADD_CLICK = 'vdp_values_add_click',
  VDP_VALUES_ADD_COST_CLICK = 'vdp_values_add_cost_click',
  VDP_VALUES_ADD_COST_SAVE_CLICK = 'vdp_values_add_cost_save_click',
  VDP_VALUES_ADD_MMR_CLICK = 'vdp_values_add_mmr_click',
  VDP_VALUES_ADD_MMR_SAVE_CLICK = 'vdp_values_add_mmr_save_click',
  VDP_VALUES_EDIT_COST_CLICK = 'vdp_values_edit_cost_click',
  VDP_VALUES_EDIT_COST_SAVE_CLICK = 'vdp_values_edit_cost_save_click',
  VDP_VALUES_EDIT_MMR_CLICK = 'vdp_values_edit_mmr_click',
  VDP_VALUES_EDIT_MMR_SAVE_CLICK = 'vdp_values_edit_mmr_save_click',
  VDP_VIEW_AUTOBIDS_CLICK = 'vdp_view_autobids_click',
  VDP_VIEW_BIDS_CLICK = 'vdp_view_bids_click',
  VDP_VIN_EDIT_CLICK = 'vdp_vin_edit_click',
  VDP_WATCHED_BY_CLICK = 'vdp_watched_by_click',
  VDP_YEAR_EDIT_CLICK = 'vdp_year_edit_click',
  VEHICLE_RELEASE_FORM_CLICK = 'vehicle_release_form_click',
  VEHICLE_SCORE_CLICK = 'vehicle_score_click',
  VIN_COPY_CLICK = 'vin_copy_click',
}
