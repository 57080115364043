// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#40618e","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","button":"button_c51fae3","theme-button":"theme-button_d81ad37","active":"active_ed73ad8","theme-base":"theme-base_b033781","hover":"hover_ec12031","attached":"attached_dd95132","outline":"outline_c17f2cc","theme-black":"theme-black_d2f7c5c","theme-black-attached":"theme-black-attached_edd594d","theme-black-outline":"theme-black-outline_e4e2c72","theme-blue":"theme-blue_c73c8c0","theme-blue-attached":"theme-blue-attached_c27f098","theme-blue-outline":"theme-blue-outline_af949d2","theme-green":"theme-green_f349bbd","theme-green-attached":"theme-green-attached_ca48ce8","theme-green-outline":"theme-green-outline_cad095c","theme-red":"theme-red_e705d33","theme-red-attached":"theme-red-attached_f0ecba2","theme-red-outline":"theme-red-outline_deee0e9","theme-gray-attached":"theme-gray-attached_ea7182b","theme-gray-outline":"theme-gray-outline_d3e321a","theme-yellow":"theme-yellow_f784578","theme-yellow-attached":"theme-yellow-attached_b421caa","theme-yellow-outline":"theme-yellow-outline_a2a6225"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#40618e\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"button\":\"button_c51fae3\",\"theme-button\":\"theme-button_d81ad37\",\"active\":\"active_ed73ad8\",\"theme-base\":\"theme-base_b033781\",\"hover\":\"hover_ec12031\",\"attached\":\"attached_dd95132\",\"outline\":\"outline_c17f2cc\",\"theme-black\":\"theme-black_d2f7c5c\",\"theme-black-attached\":\"theme-black-attached_edd594d\",\"theme-black-outline\":\"theme-black-outline_e4e2c72\",\"theme-blue\":\"theme-blue_c73c8c0\",\"theme-blue-attached\":\"theme-blue-attached_c27f098\",\"theme-blue-outline\":\"theme-blue-outline_af949d2\",\"theme-green\":\"theme-green_f349bbd\",\"theme-green-attached\":\"theme-green-attached_ca48ce8\",\"theme-green-outline\":\"theme-green-outline_cad095c\",\"theme-red\":\"theme-red_e705d33\",\"theme-red-attached\":\"theme-red-attached_f0ecba2\",\"theme-red-outline\":\"theme-red-outline_deee0e9\",\"theme-gray-attached\":\"theme-gray-attached_ea7182b\",\"theme-gray-outline\":\"theme-gray-outline_d3e321a\",\"theme-yellow\":\"theme-yellow_f784578\",\"theme-yellow-attached\":\"theme-yellow-attached_b421caa\",\"theme-yellow-outline\":\"theme-yellow-outline_a2a6225\"}";
        // 1726239932948
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  